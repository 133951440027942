import { generateLocale } from './generate.js';

export default generateLocale([
  '暱稱',
  '暱稱不能少於3個字元',
  '郵箱',
  '請填寫正確的郵件地址',
  '網址',
  '可選',
  '歡迎留言',
  '來發留言吧~',
  '送出',
  '喜歡',
  '取消喜歡',
  '回覆',
  '取消回覆',
  '留言',
  '重整',
  '載入更多...',
  '預覽',
  '表情',
  '上傳圖片',
  '秒前',
  '分鐘前',
  '小時前',
  '天前',
  '剛剛',
  '正在上傳',
  '登入',
  '登出',
  '管理者',
  '置頂',
  '字',
  '留言字數應在 $0 到 $1 字之間！\n目前字數：$2',
  '匿名',
  '潛水',
  '冒泡',
  '吐槽',
  '活躍',
  '多話',
  '傳說',
  '表情包',
  '搜尋表情包',
  '個人資料',
  '通過',
  '待審核',
  '垃圾',
  '取消置頂',
  '最早',
  '最新',
  '熱門',
  '你認為這篇文章怎麼樣？',
]);
